
.font-smoothing(@val){
  -moz-osx-font-smoothing: @val;
}

.translateZ(@val){
  -webkit-transform: translateZ(@val);
  transform: translateZ(@val);
}

.border-radius(@val) {
  -moz-border-radius: @val;
  -webkit-border-radius: @val;
  -o-border-radius: @val;
  -ms-border-radius: @val;
  border-radius: @val;
}

.wabber(@time: 300ms, @scale: 1.3, @display: inline-block) {
  display: @display;
  .font-smoothing(grayscale);
  .transition(all @time);
  //.transition-timing-function(cubic-bezier(0.68, -0.55, 0.265, 1.55));
  .transition-timing-function(cubic-bezier(0.52, 1.64, 0.37, 0.66));
  &:hover,
  &:focus {
    .scale(@scale);
  }
}


.bounce-to-top(@time: 300ms, @background: @brand-primary, @color: #fff, @display: block, @border-radius: 0, @z-index: -1) {
  display: @display;
  vertical-align: middle;
  .translateZ(0);
  .backface-visibility(hidden);
  .font-smoothing(grayscale);
  position: relative;
  .transition(color @time ease);

  &:before{
    content: "";
    position: absolute;
    z-index: @z-index;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .border-radius(@border-radius);
    background: @background;
    .scaleY(0);
    .transform-origin(50% 100%);
    .transition(transform @time ease-out);
  }

  &:hover,
  &:focus {
    color: @color;
    &:before {
      .scaleY(1);
      .transition-timing-function(cubic-bezier(0.52, 1.64, 0.37, 0.66));
    }
  }
}


.bounce-to-bottom(@time: 300ms, @background: @brand-primary, @color: #fff, @display: block, @border-radius: 0, @z-index: -1) {
  display: @display;
  vertical-align: middle;
  .translateZ(0);
  .backface-visibility(hidden);
  .font-smoothing(grayscale);
  position: relative;
  .transition(color @time ease);

  &:before{
    content: "";
    position: absolute;
    z-index: @z-index;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .border-radius(@border-radius);
    background: @background;
    .scaleY(0);
    .transform-origin(50% 0);
    .transition(transform @time ease-out);
  }

  &:hover,
  &:focus {
    color: @color;
    &:before {
      .scaleY(1);
      .transition-timing-function(cubic-bezier(0.52, 1.64, 0.37, 0.66));
    }
  }
}






.rectangle-out(@time: 300ms, @background: @brand-primary, @color: #fff, @display: block, @border-radius: 0, @z-index: -1) {
  display: @display;
  vertical-align: middle;
  .translateZ(0);
  .backface-visibility(hidden);
  .font-smoothing(grayscale);
  position: relative;
  .transition(color @time ease);

  &:before {
    content: "";
    position: absolute;
    z-index: @z-index;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .border-radius(@border-radius);
    background: @background;
    .scale(0);
    .transition(transform @time ease-out);
  }
  &:hover {
    color: @color;
    &:before {
      .scale(1);
    }
  }
}




.rectangle-out-bounce(@time: 300ms, @background: @brand-primary, @color: #fff, @display: block, @border-radius: 0, @z-index: -1) {
  display: @display;
  vertical-align: middle;
  .translateZ(0);
  .backface-visibility(hidden);
  .font-smoothing(grayscale);
  position: relative;
  .transition(color @time ease);

  &:before {
    content: "";
    position: absolute;
    z-index: @z-index;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .border-radius(@border-radius);
    background: @background;
    .scale(0);
    .transition(transform @time ease-out);
  }
  &:hover {
    color: @color;
    &:before {
      .scale(1);
      .transition-timing-function(cubic-bezier(0.52, 1.64, 0.37, 0.66));
    }
  }
}


.filter(@filter) {
  filter: @filter;
  -webkit-filter: @filter;
  -moz-filter: @filter;
  -o-filter: @filter;
  -ms-filter: @filter;
}

