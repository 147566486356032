@font-face {
  font-family: 'wgdessau-immo';
  src:url('../fonts/wgdessau-immo.eot?mnsoxg');
  src:url('../fonts/wgdessau-immo.eot?#iefixmnsoxg') format('embedded-opentype'),
  url('../fonts/wgdessau-immo.woff?mnsoxg') format('woff'),
  url('../fonts/wgdessau-immo.ttf?mnsoxg') format('truetype'),
  url('../fonts/wgdessau-immo.svg?mnsoxg#wgdessau-immo') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'wgdessau-immo';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aufzug:before {
  content: "\31";
}

.icon-badmitfenster:before {
  content: "\32";
}

.icon-balkon:before {
  content: "\33";
}

.icon-stock:before {
  content: "\34";
}

.icon-suche:before {
  content: "\35";
}

.icon-wanne:before {
  content: "\36";
}

.icon-service:before {
  content: "\37";
}

.icon-ansprechpartner:before {
  content: "\38";
}

.icon-lighticons-22:before {
  content: "\39";
}

.icon-garage:before {
  content: "\30";
}

.icon-search:before {
  content: "\77";
}

.icon-envelope-o:before {
  content: "\78";
}

.icon-user:before {
  content: "\79";
}

.icon-check:before {
  content: "\7a";
}

.icon-home:before {
  content: "\61";
}

.icon-print:before {
  content: "\62";
}

.icon-camera:before {
  content: "\63";
}

.icon-map-marker:before {
  content: "\64";
}

.icon-facebook-square:before {
  content: "\65";
}

.icon-phone:before {
  content: "\66";
}

.icon-phone-square:before {
  content: "\67";
}

.icon-google-plus-square:before {
  content: "\68";
}

.icon-envelope:before {
  content: "\69";
}

.icon-desktop:before {
  content: "\6a";
}

.icon-laptop:before {
  content: "\6b";
}

.icon-tablet:before {
  content: "\6c";
}

.icon-mobile:before {
  content: "\6d";
}

.icon-shield:before {
  content: "\6e";
}

.icon-unlock-alt:before {
  content: "\6f";
}

.icon-xing-square:before {
  content: "\70";
}

.icon-wheelchair:before {
  content: "\72";
}

.icon-fax:before {
  content: "\73";
}

.icon-child:before {
  content: "\74";
}

.icon-file-pdf-o:before {
  content: "\75";
}

.icon-key:before {
  content: "\e604";
}

.icon-location:before {
  content: "\e605";
}
