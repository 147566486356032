/**
* Contao
*/



.invisible {
  display: none;
}

// buttons
button,
input[type="submit"],
.button,
.more > a,
.back > a,
 a.more {
  .btn;
  .btn-primary;
}


// form

.ce_form {
  .submit_container {
    margin-top: 20px;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="password"],
input[type="file"],
select,
textarea {
  .form-control;
  .transition(all 300ms ease);
  color: @gray !important;
  &:focus {
    color: @brand-primary !important;
  }
}

label {
  font-weight: 400;
  display: block;
}

span {

  > input[type="checkbox"] {
    display: inline-block;
  }

  > label {
    display: inline-block;
  }
}


textarea {
  height: 120px;
}

span.mandatory {
  color: @brand-danger;
}

.ce_divider {
  margin-top: 30px;
  margin-bottom: 50px;
  hr {
    border-color: #e4e4e4 -moz-use-text-color -moz-use-text-color;
  }
}

select {
  .form-control;
}

// article
// syndication
.mod_article {
  .pdf_link {
    .icon {
      font-size: 35px;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
}





// pagination
ul.pagination {
  span.current {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
      cursor: default;
    }
  }
}


// ce_slider
.ce_sliderStart {
  .slider-next,
  .slider-prev {
    display: none;
  }
}






// news
.mod_newsarchive,
.mod_newslist {
  .layout_latest {


    .avatar img {
      margin-bottom: 20px;
      .filter(contrast(1));
      .transition(all 300ms ease);
      &:hover,
      &:focus {
        .filter(contrast(1.8));
      }
    }
  }

}

.mod_newsreader {
  .info {

  }
}


.mod_newsmenu {
  ul.level_2 {
    margin-top: 2px;
  }
}


// mm ansprechpartner
.clearall {
  a {
    .btn;
    .btn-primary;
  }
}




// mm ansprechpartner list startseite
.mitarbeiter-list-startseite {
  .mitarbeiter {
    margin-bottom: 30px;
    img {
      .filter(contrast(1));
      .transition(all 300ms ease);
      &:hover,
      &:focus {
        .filter(contrast(1.8));
      }
    }
  }
}
// mm mitarbeiter
.mitarbeiter-list {
  a {
    img {
      .filter(contrast(1));
      .transition(all 300ms ease);
      &:hover,
      &:focus {
        .filter(contrast(1.8));
      }
    }
  }
}


// downloadarchive
.ce_downloadarchive {
  a {
    img {
      .filter(contrast(1));
      .transition(all 300ms ease);
      &:hover,
      &:focus {
        .filter(contrast(1.8));
      }
    }
  }
}








// blueimp
div.gallery {

  .gallery-item {

    padding-top: 15px;
    padding-bottom: 15px;
    list-style-type: none;
    a {
      display: block;
      border: 10px solid #e2e2e2;
      &:hover,
      &:focus {
        border-color: @brand-primary;
      }
    }
    img {
      width: 100% !important;
      height: auto !important;
      .filter(contrast(1));
      .transition(all 300ms ease);
      &:hover,
      &:focus {
        .filter(contrast(1.8));
      }
    }
  }
}

// = player
.mejs-container .mejs-controls > div:last-of-type {
  position: relative;
  clear: both;
  float: right;
  //top:   -31px;
  top: -26px;
}

// = metamodels
// offers









