// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";
@import "glyphicons.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
@import "tables.less";
@import "forms.less";
@import "buttons.less";

// Components
@import "component-animations.less";
@import "dropdowns.less";
@import "button-groups.less";
@import "input-groups.less";
@import "navs.less";
@import "navbar.less";
@import "breadcrumbs.less";
@import "pagination.less";
@import "pager.less";
@import "labels.less";
@import "badges.less";
@import "jumbotron.less";
@import "thumbnails.less";
@import "alerts.less";
@import "progress-bars.less";
@import "media.less";
@import "list-group.less";
@import "panels.less";
@import "responsive-embed.less";
@import "wells.less";
@import "close.less";

// Components w/ JavaScript
@import "modals.less";
@import "tooltip.less";
@import "popovers.less";
@import "carousel.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

// custom
@import "contao.less";
@import "icons.less";



body {
  // padding-top: 60px;
  //background: red;
}

a,
input[type="submit"],
button {
  .transition(all 300ms ease);
}

a {


  &:hover,
  &:focus {
    text-decoration: none;

  }
}


audio,
video {
  width: 100% !important;
}

// selection
::selection {
  background: @brand-primary;
  color: #fff;
}

::-moz-selection {
  background: @brand-primary;
  color: #fff;
}


// hr
hr {
  border-color: #ccc;
  border-style: dashed;
  border-width: thin;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-top: 50px;
  margin-bottom: 50px;
}


h1, h2, h3, h4, h5, h6 {
  .hyphens();
}


// file
.btn-file {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}







/********************************************
* Bootstrap
*********************************************/


// media-heading
.media-heading {
  @media (max-width: @screen-xs-max) {
    margin-top: 22px;
  }
}

// navbar
.navbar {
  margin-bottom: 0;
  //border: none;

  .navbar-brand {
    opacity: 1;

    &:hover,
    &:focus {
      opacity: .5;
    }

    img {
      height: 70px;
      width: auto;
      margin-top: -22px;
    }
  }


  .navbar-right.mod_navigation {
    margin-right: 15px;
  }
  @media (min-width: @grid-float-breakpoint) {
    .navbar-btn {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}

.navbar-inverse {
  //border: none;


}

// language switch
.navbar {



}

.navbar-form {
  margin: 32px -15px;
}


// pills
.nav-pills {
  > li {
    > a {
      background: @gray-lighter;
      //color: @gray;
      &:hover,
      &:focus {
        color: white;
        background: @brand-primary;
      }
    }
  }
}



.text-white {
  color: white;
  h1, h2, h3, h4, h5, h6 {
    color: white;
  }
  p {
    color: white;
  }
}

// nav
.nav-pills {
  .level_2 {
    li.active {
      a {
        .badge {
          background: #fff;
          color: @brand-primary;
        }
      }
    }
    li {
      a {
        padding-left: 40px;

        .badge {
          background: @brand-primary;
          color: #fff;
        }
        &:hover {
          .badge {
            background: #fff;
            color: @brand-primary;
          }
        }
      }
    }
  }
}



/**
* Layout
*/

#wrapper {
  overflow-x: hidden;
}

.shadow {
  .box-shadow(0px 10px 30px 0px rgba(0,0,0,0.4));
}


#header {
  color: #fff;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  h1, h2, h3, h4, h5, h6,
  p {
    color: #fff;
  }

  .bg-image {
    min-height: 500px;
    position: relative;
  }
  .banner {
    width: 100%;
    height: 120px;
    opacity: .7;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .wohnung-finden {
    .btn-lg {
      padding: 20px 30px;
    }
  }

  .toolbar {
    margin-top: 50px;
    margin-bottom: 50px;
    h1, h2, h3, h4, h5, h6 {
      color: @gray;
    }
    p {
      color: @gray;
    }
  }

  @media (min-width: @grid-float-breakpoint) {
    .wohnung-finden {
      .hidden();
    }
  }



  @media (min-width: @screen-sm-min) {
    h1 {
      font-size: floor((@font-size-base * 4));
    }
    h2 {
      font-size: floor((@font-size-base * 3.5));
    }
    h3 {
      font-size: floor((@font-size-base * 3));
    }
    h4 {
      font-size: floor((@font-size-base * 2));
    }
    h5 {
      font-size: @font-size-base;
    }
    h6 {
      font-size: ceil((@font-size-base * .9));
    }

    p {
      font-size: floor((@font-size-base * 2));
    }
  }

  .filter-bg {
    padding-top: 30px;
    padding-bottom: 180px;
    background: rgba(0, 0, 0, .4);
    height: 100%;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 400;
    }

    p {
      font-size: 16px;
    }

    h1 {
      font-size: floor((@font-size-base * 2.6));
    }
    h2 {
      font-size: floor((@font-size-base * 2.15));
    }
    h3 {
      font-size: floor((@font-size-base * 1.7));
    }
    h4 {
      font-size: ceil((@font-size-base * 1.25));
    }
    h5 {
      font-size: @font-size-base;
    }
    h6 {
      font-size: ceil((@font-size-base * 0.85));
    }
  }

  .mm_select {
    margin-bottom: 15px;
  }


}


#container {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow-x: hidden;

  #main {

    > .inside {

    }
  }

  #right {

    > .inside {

        .image {
          margin-top: 30px;
        }
    }
  }

}
#footer {

  color: #fff;
  overflow-x: auto;
  background: #555;
  min-height: 300px;
  position: relative;

  padding-top: 80px;
  padding-bottom: 150px;

  h1, h2, h3, h4, h5, h6,
  p {
    color: #fff;
  }

  a {
    color: @gray-lighter;
    &:hover,
    &:focus {
      color: @brand-primary;
    }
  }

  .linklist {
    font-size: 20px;
  }

  > .inside {

  }

  .banner {
    width: 100%;
    height: 120px;
    position: absolute;
    left: 0;
    bottom: 0;
  }



}


//= Print
@media print {

  a[href]:after {
    content: none !important;
  }

  @page {
    margin: 0;
    padding: 0;
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  body {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    margin: 0;
    padding: 0;
  }


  #header,
  #footer,
  .pdf_link,
  .btn,
  .back,
  #right {
    display: none;
  }

  #print-header {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px dashed @brand-primary;
    overflow: auto;
    p {
      color: @gray;
    }



    // logo
    .logo {
      img {
        display: block;
      }
      width: 300px;
      height: auto;
    }
  }




}




